// src/components/Admin/data/Morpen.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import JSZip from "jszip"; // Import JSZip to create ZIP files
import cdpImage from "../../assets/morpen/cdp_image.bmp"; // CDP image to include in the ZIP
import "./CINSelector.css"; // Use it locally, but don't put it in the ZIP
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Morpen = ({ product }) => {
  const [startValue, setStartValue] = useState(248);
  const [erpBatches, setErpBatches] = useState([]);
  const [selectedBatchIndex, setSelectedBatchIndex] = useState("");
  const [batchQuantity, setBatchQuantity] = useState("");
  const [downloadMessage, setDownloadMessage] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState("");

  // ============= 1) On Load: Fetch latest order =============
  useEffect(() => {
    const getLatestOrder = async () => {
      try {
        const res = await axios.get(
          "https://w2d1g7akg3.execute-api.ap-south-1.amazonaws.com/dev/latest"
        );
        if (res.data?.item && typeof res.data.item.idTO === "number") {
          setStartValue(res.data.item.idTO + 1);
        } else {
          setStartValue(248);
        }
      } catch (err) {
        console.error("Error fetching latest order:", err);
        toast.error("Failed to fetch latest order. Using default=248.");
        setStartValue(248);
      }
    };
    getLatestOrder();
  }, []);

  // ============= 2) On Load: Fetch all ERP batches =============
  useEffect(() => {
    const fetchAllErp = async () => {
      try {
        const response = await axios.get(
          "https://w2d1g7akg3.execute-api.ap-south-1.amazonaws.com/dev/all-erp"
        );
        if (response.data?.items) {
          setErpBatches(response.data.items);
        }
      } catch (error) {
        console.error("Error fetching ERP batches:", error);
        toast.error("Failed to fetch ERP batches.");
      }
    };
    fetchAllErp();
  }, []);

  // ------------------------------------------------------------------
  // Minimal "Encryption": Just Base64 with window.btoa and .atob
  // ------------------------------------------------------------------
  function encryptBase64(str) {
    // Remove trailing '=' to shorten
    return window.btoa(str).replace(/=+$/, "");
  }
  function decryptBase64(base64Str) {
    // Re-pad if needed
    const neededPadding = base64Str.length % 4;
    if (neededPadding) {
      base64Str += "====".slice(0, 4 - neededPadding);
    }
    return window.atob(base64Str);
  }

  // ------------------------------------------------------------------
  // Handler: Download codes, post to API, generate ZIP with CSV & CDP image, and update record
  // ------------------------------------------------------------------
  const handleBatchDownload = async () => {
    if (selectedProductIndex === "") {
      toast.error("Please select a product first.");
      return;
    }
    if (!batchQuantity) {
      toast.error("Please enter Batch Quantity.");
      return;
    }
    if (selectedBatchIndex === "") {
      toast.error("Please select a Batch ID from the ERP table.");
      return;
    }

    const qty = Number(batchQuantity);
    const endValue = startValue + qty - 1;

    // Retrieve chosen data
    const selectedProd = product[selectedProductIndex];
    const attachedUid = selectedProd?.attachedUid?.[0] || "1739776075417";

    const chosenErp = erpBatches[selectedBatchIndex];
    const selectedBatchID = chosenErp.batchID;
    const selectedExpiryDate = chosenErp.expiryDate;
    const selectedMfgDate = chosenErp.manufactureDate;

    // 1) Post record to DB (optional)
    const createdAt = Date.now();
    const orderData = {
      orderid: `ORDER-${createdAt}`,
      productName: selectedProd.title,
      productUID: attachedUid,
      productQty: qty,
      idFROM: startValue,
      idTO: endValue,
      batchID: selectedBatchID,
      expiryDate: selectedExpiryDate,
      manufactureDate: selectedMfgDate,
      createdAt,
    };

    try {
      await axios.post(
        "https://w2d1g7akg3.execute-api.ap-south-1.amazonaws.com/dev/",
        orderData
      );
      toast.success("Order record posted successfully!");
    } catch (err) {
      console.error("Error posting order record:", err);
      toast.error("Failed to store order record in DB.");
    }

    // 2) Generate Base64 codes
    let codes = [];
    try {
      codes = Array.from({ length: qty }, (_, i) => {
        const portion = `${attachedUid}x${startValue + i}`;
        const encUid = encryptBase64(portion);
        const encBatch = encryptBase64(selectedBatchID);
        return `https://media.arvo.services/?uid=${encUid}&d=${encBatch}`;
      });
    } catch (error) {
      console.error("Error generating codes:", error);
      toast.error("Error generating codes.");
      return;
    }

    // 3) Post the list of URLs to the API endpoint and then generate CSV from hexIds
    try {
      const payload = { originalURLs: codes };
      const response = await axios.post(
        "https://4kiwkfgefb.execute-api.ap-south-1.amazonaws.com/dev/url",
        payload
      );

      // The response should include hexIds
      const { hexIds } = response.data;
      if (!hexIds || !Array.isArray(hexIds)) {
        throw new Error("Invalid response from URL API");
      }

      // Create CSV content: each line is "https://4rvo.com/{hexId}"
      const csvString = hexIds
        .map((hex) => `https://4rvo.com/${hex}`)
        .join("\n");

      // Create file names using product title and batch ID.
      const currentDate = format(new Date(), "yyyyMMdd");
      // For ZIP file, include date
      const zipFileName = `${selectedProd.title}-${selectedBatchID}-${currentDate}.zip`;
      // For CSV file, no date included
      const csvFileName = `${selectedProd.title}-${selectedBatchID}.csv`;

      // 4) Create a ZIP file that includes the CSV and the CDP image
      const zip = new JSZip();
      // Add CSV file with dynamic name
      zip.file(csvFileName, csvString);
      // Fetch the CDP image file and add it to the ZIP
      const imageResp = await fetch(cdpImage);
      const imageBlob = await imageResp.blob();
      zip.file("cdp_image.bmp", imageBlob);

      // Generate the ZIP blob and trigger download with the dynamic file name
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, zipFileName);

      // 5) Set the new custom message and update startValue
      setDownloadMessage(
        `ZIP generated and downloaded (${zipFileName}). CSV (${csvFileName}) and CDP image included. Batch processed from ${startValue} to ${endValue}.`
      );
      setStartValue(endValue + 1);
      toast.success("ZIP generated and URLs posted successfully!");
    } catch (error) {
      console.error("Error posting URLs or generating ZIP:", error);
      toast.error("Error posting URLs or generating ZIP.");
    }
  };

  // Demo: decode a Base64 string
  const handleDecryptDemo = () => {
    const base64Val = prompt("Paste a base64 string to decode (no '=' is okay).");
    if (!base64Val) return;
    try {
      const decoded = decryptBase64(base64Val);
      toast.info(`Decrypted => "${decoded}"`);
    } catch (err) {
      console.error("Decryption error:", err);
      toast.error("Failed to decode. See console.");
    }
  };

  // ------------------------------------------------------------------
  // Inline Styles
  // ------------------------------------------------------------------
  const downloadButtonStyles = {
    background: "linear-gradient(92.49deg, #0956CC 0%, #1A4E9F 100%)",
    boxShadow:
      "4px 4px 16px 0px #FFFFFF33 inset, -4px -4px 16px 0px #00000040 inset, 0px 4px 4px 0px #00000040",
    color: "white",
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    height: "40px",
    borderRadius: "24px",
    fontFamily: "'Roboto', sans-serif",
    transition: "background-color 0.3s ease",
  };

  const inputStyle = {
    width: "100%",
    padding: "10px",
    marginBottom: "12px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "16px",
    fontFamily: "'Roboto', sans-serif",
  };

  const labelStyle = {
    fontWeight: "600",
    marginBottom: "6px",
    display: "block",
    fontFamily: "'Roboto', sans-serif",
  };

  // ------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------
  return (
    <div
      style={{
        height: "100%",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        marginBottom: "40px",
        border: "1px solid #ddd",
        borderRadius: "8px",
      }}
    >
      <div
        className="batch-selector-container"
        style={{ maxWidth: "500px", margin: "0 auto" }}
      >
        <h2
          className="batch-selector-title"
          style={{ marginBottom: "20px", textAlign: "center" }}
        >
          ERP Batch Details
        </h2>

        {/* Batch Number */}
        <div className="form-group">
          <label style={labelStyle}>Batch Number:</label>
          <select
            style={inputStyle}
            value={selectedBatchIndex}
            onChange={(e) => setSelectedBatchIndex(e.target.value)}
          >
            <option value="">-- Select Batch --</option>
            {erpBatches.map((batch, idx) => (
              <option key={idx} value={idx}>
                {batch.batchID}
              </option>
            ))}
          </select>
        </div>

        {/* If a batch is chosen, show details */}
        {selectedBatchIndex !== "" && (
          <div
            style={{
              margin: "10px 0",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
          >
            <p style={{ fontWeight: "600", marginBottom: "6px" }}>
              Expiry Date:
            </p>
            <p style={{ marginBottom: "10px" }}>
              {erpBatches[selectedBatchIndex].expiryDate}
            </p>

            <p style={{ fontWeight: "600", marginBottom: "6px" }}>
              Manufacture Date:
            </p>
            <p>{erpBatches[selectedBatchIndex].manufactureDate}</p>
          </div>
        )}

        {/* Batch Quantity */}
        <div className="form-group">
          <label style={labelStyle}>Batch Quantity:</label>
          <input
            type="number"
            style={inputStyle}
            value={batchQuantity}
            onChange={(e) => setBatchQuantity(e.target.value)}
            min="1"
          />
        </div>

        {/* "Link Product" */}
        <div className="form-group">
          <label style={labelStyle}>Link Product:</label>
          <select
            style={inputStyle}
            value={selectedProductIndex}
            onChange={(e) => setSelectedProductIndex(e.target.value)}
          >
            <option value="">Select a Product</option>
            {product.map((p, index) => (
              <option key={index} value={index}>
                {p.title} (UID: {p.attachedUid?.[0]})
              </option>
            ))}
          </select>
        </div>

        {/* Show selected product details */}
        {selectedProductIndex !== "" && (
          <div
            style={{
              margin: "10px 0",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
          >
            <p style={{ marginBottom: "5px", fontWeight: "600" }}>
              Selected Product:
            </p>
            <p style={{ marginBottom: "5px", fontWeight: "bold" }}>
              Title: {product[selectedProductIndex].title}
            </p>
            {product[selectedProductIndex].photo?.[0] && (
              <img
                src={product[selectedProductIndex].photo[0]}
                alt={product[selectedProductIndex].title}
                style={{ maxWidth: "60%", marginTop: "5px" }}
              />
            )}
          </div>
        )}

        {/* Download Button */}
        {selectedBatchIndex !== "" &&
          selectedProductIndex !== "" &&
          batchQuantity && (
            <button onClick={handleBatchDownload} style={downloadButtonStyles}>
              Download Base64 Codes
            </button>
          )}
      </div>

      {/* Download Message & Decrypt Demo */}
      {downloadMessage && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            backgroundColor: "#eaeaea",
            whiteSpace: "pre-line",
            fontFamily: "'Roboto', sans-serif",
            maxWidth: "500px",
            margin: "20px auto 0",
          }}
        >
          {downloadMessage}
          <br />
          <br />
          <button
            onClick={handleDecryptDemo}
            style={{
              marginTop: "10px",
              border: "none",
              padding: "8px 14px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Test Decode
          </button>
        </div>
      )}

      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default Morpen;
