import React, { useState, useCallback, useEffect, useRef } from "react";
import "../ProductForm/Form.css";
import downArrow from "../../assets/dropdownarrowblack.png";
import { useParams, useNavigate } from "react-router-dom";
import { useDropzone, FileRejection } from "react-dropzone";
import axios from "axios";
import arvologo from "../../assets/arvologo.png";
import { RootState } from "../../redux/store";
import Instagramlogo from "../../assets/instagram-logo.png";
import Websitelogo from "../../assets/website.png";
import LinkedInlogo from "../../assets/icons8-linkedin-logo-50.png";
import Facebooklogo from "../../assets/icons8-facebook-64.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SwatchesPicker } from "react-color";
import customizeImage from "../../assets/dropdownarrowblack.png";
import Closedcustomize from "../../assets/dropdownup.png";
import insta from "../../assets/insta.png";
import web from "../../assets/web.png";
import facebook from "../../assets/fb.png";
import linkedin from "../../assets/linkedin.png";
import { useSelector } from "react-redux";
import InputMask from 'react-input-mask';

interface CheckboxOption {
  [fieldName: string]: boolean;
}
interface EditData {
  title: string;
  bgcolor: string;
  createdAt: any;
  textcolor: string;
  headingcolor: string;
  description: string;
  batchNumber: string;
  // manufacturingDate: string;
  // expiryDate: string;
  janaushadhiCode: string;
  price: string;
  link: string[];
  brandlink: string;
  productdicPrice: string;
  mission: string;
  attachedUid: string[];
  brandDetail: string;
  photo: string[];
  videoLinks: VideoLink[];
  category: string;
  keyfeature: string[];
  button1text: string;
  button1URL: string;
  button2text: string;
  button2URL: string;
  diresctiontouse: { image: string; text: string }[];
  socialMediaLinks: SocialMediaLink[];
  sustainability: { image: string; text1: string; text2: string }[];
  tagline: string;
  nutritionItems: NutritionItem[];
  packagetext: string;
}

interface SocialMediaLink {
  id: number;
  platform: string;
  url: string;
}

interface KeyBenefit {
  id: number;
  value: string;
}
interface VideoLink {
  id: number;
  value: string;
}
interface NutritionItem {
  name: string;
  value: string;
  unit: string;
}

const Formedit: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productdicPrice, setProductdicPrice] = useState("");
  const [createdAt, setCreadtedAt] = useState("");
  const [brandlink, setbrandlink]: any = useState("");
  const [brandMission, setBrandMission] = useState("");
  const [brandStory, setBrandStory] = useState("");
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [packagetext, setPackage] = useState<string>();
  const [skuid, setSKUID] = useState("");
  const [keyFeature, setKeyFeature] = useState("");
  const [nutritionItems, setNutritionItems] = useState<NutritionItem[]>([
    { name: "", value: "", unit: "g" },
  ]);
  const type = localStorage.getItem("subtype");

  const [showInputs, setShowInputs] = useState(false);
  // const [manufacturingDate, setManufacturingDate] = useState("");
  // const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [janaushadhiCode, setJanaushadhiCode] = useState("");

  const textareaClass = type === "type2" ? "" : "blur-content-textarea";
  const [button1text, setButton1text]: any = useState("");
  const [button2text, setButton2text]: any = useState("");
  const [button1URL, setButton1URL]: any = useState("");
  const [button2URL, setButton2URL]: any = useState("");
  const [link, setlink]: any = useState("");
  const [currentImage, setCurrentImage] = useState(1);
  const [selectedItem, setSelectedItem] = useState("Product Category");
  const [keyBenefits, setKeyBenefits] = useState<KeyBenefit[]>([
    { id: Date.now(), value: "" },
  ]);
  const [videoLinks, setVideoLinks] = useState<VideoLink[]>([
    { id: Date.now(), value: "" },
  ]);
  const [tagline, settagline] = useState("");
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([""]);
  const [textInputs, setTextInputs] = useState<string[]>([""]);
  // const fileInputRefs = useRef<(HTMLInputElement | null)[]>([null]);
  const tenantdata: any = useSelector(
    (state: RootState) => state.counter.tenentData
  );

  const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLink[]>([
    { id: 1, platform: "", url: "" },
  ]);

  const socialMediaOptions = [
    { platform: "Instagram", icon: insta },
    { platform: "Facebook", icon: facebook },
    { platform: "Website", icon: web },
    { platform: "LinkedIn", icon: linkedin },
  ];
  const handleSocialMediaChange = (
    id: number,
    field: "platform" | "url",
    value: string
  ) => {
    setSocialMediaLinks((prevLinks) =>
      prevLinks.map((link) =>
        link.id === id ? { ...link, [field]: value } : link
      )
    );
  };
  const addSocialMediaLink = () => {
    if (socialMediaLinks.length < 4) {
      setSocialMediaLinks((prevLinks) => [
        ...prevLinks,
        { id: prevLinks.length + 1, platform: "", url: "" },
      ]);
    }
  };

  const deleteSocialMediaLink = (id: number) => {
    setSocialMediaLinks((prevLinks) =>
      prevLinks.filter((link) => link.id !== id)
    );
  };

  const getAvailableSocialMediaOptions = () => {
    const selectedPlatforms = socialMediaLinks.map((link) => link.platform);
    return socialMediaOptions.filter(
      (option) => !selectedPlatforms.includes(option.platform)
    );
  };

  const [imageTextData, setImageTextData] = useState<
    { image: string; text: string }[]
  >([{ image: "", text: "" }]);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([null]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const newImageTextData = [...imageTextData];
        newImageTextData[index] = {
          image: base64String,
          text: imageTextData[index]?.text || "",
        };
        setImageTextData(newImageTextData);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const newImageTextData = [...imageTextData];
    newImageTextData[index] = {
      image: imageTextData[index]?.image || "",
      text: event.target.value,
    };
    setImageTextData(newImageTextData);
  };

  const handleImageSelectorClick = (index: number) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index]!.click();
    }
  };

  const handleAddNew = () => {
    setImageTextData((prevData) => [...prevData, { image: "", text: "" }]);
    fileInputRefs.current.push(null);
  };

  const handleRemove = (index: number) => {
    const newImageTextData = [...imageTextData];
    fileInputRefs.current.splice(index, 1);
    newImageTextData.splice(index, 1);
    setImageTextData(newImageTextData);
  };

  const [sustainabilityData, setSustainabilityData] = useState<
    { image: string; text1: string; text2: string }[]
  >([{ image: "", text1: "", text2: "" }]);
  const sustainabilityFileInputRefs = useRef<(HTMLInputElement | null)[]>([
    null,
  ]);

  const handleSustainabilityFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const newSustainabilityData = [...sustainabilityData];
        newSustainabilityData[index] = {
          image: base64String,
          text1: sustainabilityData[index]?.text1 || "",
          text2: sustainabilityData[index]?.text2 || "",
        };
        setSustainabilityData(newSustainabilityData);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSustainabilityTextareaChange1 = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const newSustainabilityData = [...sustainabilityData];
    newSustainabilityData[index] = {
      image: sustainabilityData[index]?.image || "",
      text1: event.target.value,
      text2: sustainabilityData[index]?.text2 || "",
    };
    setSustainabilityData(newSustainabilityData);
  };

  const handleSustainabilityTextareaChange2 = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const newSustainabilityData = [...sustainabilityData];
    newSustainabilityData[index] = {
      image: sustainabilityData[index]?.image || "",
      text1: sustainabilityData[index]?.text1 || "",
      text2: event.target.value,
    };
    setSustainabilityData(newSustainabilityData);
  };

  const handleSustainabilityImageSelectorClick = (index: number) => {
    if (sustainabilityFileInputRefs.current[index]) {
      sustainabilityFileInputRefs.current[index]!.click();
    }
  };

  const handleSustainabilityAddNew = () => {
    setSustainabilityData((prevData) => [
      ...prevData,
      { image: "", text1: "", text2: "" },
    ]);
    sustainabilityFileInputRefs.current.push(null);
  };

  const handleSustainabilityRemove = (index: number) => {
    const newSustainabilityData = [...sustainabilityData];
    sustainabilityFileInputRefs.current.splice(index, 1);
    newSustainabilityData.splice(index, 1);
    setSustainabilityData(newSustainabilityData);
  };

  const handlePackageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPackage(event.target.value);
  };

  useEffect(() => {
    const tenant = localStorage.getItem("tenant");

    if (tenantdata !== null && typeof tenantdata !== "undefined") {
      // const tenantdata: any = JSON.parse(tenantdatas);

      const selectedOrder = tenantdata.find(
        (order: any) => order.employeeid === tenant
      );

      if (selectedOrder) {
        setbrandlink(selectedOrder.profilephoto[0]);
      } else {
        console.error("No order found for the current tenant");
      }
    } else {
      console.error("Tenant data is missing or invalid.");
    }
  }, []);

  const handleAddNutritionItem = () => {
    setNutritionItems([...nutritionItems, { name: "", value: "", unit: "g" }]);
  };

  const handleRemoveNutritionItem = (index: number) => {
    const newNutritionItems = [...nutritionItems];
    newNutritionItems.splice(index, 1);
    setNutritionItems(newNutritionItems);
  };

  const handleNutritionChange = (
    index: number,
    field: "name" | "value" | "unit",
    value: string
  ) => {
    const newNutritionItems = [...nutritionItems];
    newNutritionItems[index][field] = value;
    setNutritionItems(newNutritionItems);
  };
  const [editdatas, setEditDatas] = useState<EditData | null>(null);
  const { id } = useParams();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload/?id=${id}`
        );

        if (response) {
          const responseData = response.data.Item as EditData;
          console.log(responseData);
          setEditDatas(responseData);
          setlink(responseData?.link || "");
          settagline(responseData?.tagline || "");
          setPackage(responseData?.packagetext || "");
          setNutritionItems(responseData?.nutritionItems || []);
          setVideoLinks(responseData?.videoLinks || []);
          setCreadtedAt(responseData?.createdAt || "");
          setProductName(responseData?.title || ""); // Set default value as empty string
          setProductDescription(responseData?.description || "");
          setProductPrice(responseData?.price || "");
          setBatchNumber(responseData?.batchNumber || "");
          // setManufacturingDate(responseData?.manufacturingDate || "");
          // setExpiryDate(responseData?.expiryDate || "");
          setJanaushadhiCode(responseData?.janaushadhiCode || "");
          setProductdicPrice(responseData?.productdicPrice || "");
          setBrandMission(responseData?.mission || "");
          setNutritionItems(responseData?.nutritionItems || []);
          setButton1text(responseData?.button1text || "");
          setButton1URL(responseData?.button1URL || "");
          setButton2text(responseData?.button2text || "");
          setButton2URL(responseData?.button2URL || "");
          setSocialMediaLinks(responseData?.socialMediaLinks || []);
          setSustainabilityData(responseData?.sustainability || []);
          setImageTextData(responseData?.diresctiontouse || []);
          setButton1text(responseData?.button1text || "");
          setButton2text(responseData?.button2text || "");
          setButton1URL(responseData?.button1URL || "");
          setButton2URL(responseData?.button2URL || "");
          setKeyBenefits(
            (responseData?.keyfeature || []).map((value, index) => ({
              id: index,
              value: value,
            }))
          );
          setSelectedItem(responseData?.category || "");
          setBrandMission(responseData?.mission || "");
          setBrandStory(responseData?.brandDetail || "");
          const imagedata = responseData?.photo || [];
          const convertedimagedata = imagedata.map(convertToHttps);
          setImageUrls(convertedimagedata); // Ensure you handle the case of photo being undefined

          if (response.data.Item.bgcolor && response.data.Item.bgcolor.length) {
            setColor(responseData?.bgcolor.replace(/"/g, "") || "");
            setTextColor(responseData?.textcolor.replace(/"/g, "") || "");
            setHeadingColor(responseData?.headingcolor.replace(/"/g, "") || "");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);
  const defaultColor =
    editdatas && editdatas.bgcolor ? editdatas.bgcolor.replace(/"/g, "") : "";
  const defaultTextColor =
    editdatas && editdatas.textcolor
      ? editdatas.textcolor.replace(/"/g, "")
      : "";
  const defaultHeadingColor =
    editdatas && editdatas.headingcolor
      ? editdatas.headingcolor.replace(/"/g, "")
      : "";

  const [color, setColor] = useState<string>(defaultColor);
  const [textcolor, setTextColor] = useState<string>(defaultTextColor);
  const [headingcolor, setHeadingColor] = useState<string>(defaultHeadingColor);
  const handleProducttaglineChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    settagline(event.target.value);
  };

  const [formData, setFormData] = useState<{
    productName: string;
    productDescription: string;
    productPrice: string;
    brandMission: string;
    brandStory: string;
    keyBenefits: KeyBenefit[];
    videoLinks: VideoLink[];
  }>({
    productName: editdatas?.title || "",
    productDescription: editdatas?.description || "",
    productPrice: editdatas?.price || "",
    brandMission: editdatas?.mission || "",
    brandStory: editdatas?.brandDetail || "",
    keyBenefits: [{ id: Date.now(), value: "" }],
    videoLinks: [{ id: Date.now(), value: "" }],
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newUrls = acceptedFiles.map((file: File) =>
      URL.createObjectURL(file)
    );
    setImageUrls((prevUrls) => [...prevUrls, ...newUrls]);
  }, []);

  const removePhoto = (index: number) => {
    // Remove the photo at the specified index
    const updatedPhotos = [...imageUrls];
    updatedPhotos.splice(index, 1);
    setImageUrls(updatedPhotos);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
  });
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOption>({});

  // Function to handle checkbox change
  const handleCheckboxChange = (fieldName: string, checked: boolean) => {
    setCheckboxOptions((prevOptions) => ({
      ...prevOptions,
      [fieldName]: checked,
    }));
  };
  const handleColorChange = (newColor: any) => {
    setColor(newColor.hex);
  };
  const handleheaingColorChange = (newColor: any) => {
    setHeadingColor(newColor.hex);
  };
  const handleTextColorChange = (newColor: any) => {
    setTextColor(newColor.hex);
  };

  const [customizeing, setcustomizing] = useState(false);
  const customize = () => {
    setcustomizing(!customizeing);
  };

  const convertToHttps = (url: string): string => {
    if (url.startsWith("http://")) {
      return url.replace("http://", "https://");
    }

    if (url.startsWith("https://")) {
      return url;
    }

    return url;
  };

  const convertedbrandlnk = convertToHttps(brandlink);

  const handleSubmit = async () => {
    setIsSubmitting(true); // Set submitting state to true
    // console.log("Submitting data:", {
    //   productName,
    //   productDescription,
    //   productPrice,
    //   brandMission,
    //   brandStory,
    //   selectedItem,
    //   keyBenefits: keyBenefits.map((benefit) => benefit.value),
    //   videoLinks: videoLinks.map((link) => link.value),
    // });

    const convertedUrls: string[] = imageUrls.map(convertToHttps);
    // console.log(convertedUrls);
    // console.log(imageUrls)
    const base64Images = await Promise.all(
      convertedUrls.map(async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result;
            if (typeof base64data === "string") {
              resolve(base64data);
            } else {
              throw new Error("Error converting image to base64");
            }
          };
          reader.readAsDataURL(blob);
        });
      })
    );

    const formData = {
      productName,
      productDescription,
      productPrice,
      brandMission,
      brandStory,
      imageUrls: base64Images,
      selectedItem,
      keyBenefits: keyBenefits.map((benefit) => benefit.value),
      videoLinks: videoLinks.map((link) => link.value),
    };

    const apiData = {
      id: id,
      photo: base64Images,
      checkboxOptions: checkboxOptions,
      title: formData.productName,
      brandDetail: formData.brandStory,
      price: formData.productPrice,
      nutritionItems,
      packagetext,
      imageTextData,
      sustainabilityData,
      tagline,
      socialMediaLinks,
      brandlink,
      button1URL,
      button1text,
      // manufacturingDate,
      // expiryDate,
      // batchNumber,
      janaushadhiCode,
      button2URL,
      button2text,
      links: link,
      discountmrp: productdicPrice,
      description: formData.productDescription,
      bgcolor: color,
      videoLinks: videoLinks,
      textcolor: textcolor,
      headingcolor: headingcolor,
      email: "adikumar1238@gmail.com",
      category: selectedItem,
      createdAt: createdAt,
      lastmodification: Date.now(),
      keyfeature: formData.keyBenefits.map((benefit): string => {
        if (typeof benefit === "string") {
          return benefit;
        } else {
          return (benefit as KeyBenefit).value;
        }
      }),
      mission: formData.brandMission || "",
      attachedUid: editdatas?.attachedUid,
      tenant: localStorage.getItem("tenant")?.toLowerCase() || "",
    };

    // console.log(apiData);

    try {
      const response = await fetch(
        "https://xc5ph8i3p0.execute-api.ap-south-1.amazonaws.com/prod/upload",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiData),
        }
      );
      if (response.ok) {
        console.log("Data submitted successfully");
        toast.success(`${formData.productName} Edited successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/product");
        }, 3000);
      } else {
        console.error("Failed to submit data");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const addVideoLink = () => {
    if (videoLinks.length < 4) {
      setVideoLinks([...videoLinks, { id: Date.now(), value: "" }]);
    } else {
      toast.error("You can only add up to 4 video links.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const urls = Array.from(files).map((file) => URL.createObjectURL(file));
      setImageUrls(urls);
    }
  };
  const deleteVideoLink = (id: number) => {
    setVideoLinks(videoLinks.filter((link) => link.id !== id));
  };

  const handleVideoLinkChange = (id: number, value: string) => {
    const updatedLinks = videoLinks.map((link) =>
      link.id === id ? { ...link, value: value } : link
    );
    setVideoLinks(updatedLinks);
  };

  const handleProductNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductName(event.target.value);
  };

  const handleProductDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setProductDescription(event.target.value);
  };
  const handleBrandMissionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setBrandMission(event.target.value);
  };
  const handleBrandStoryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setBrandStory(event.target.value);
  };

  const handleProductPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductPrice(event.target.value);
  };

  const handleDisProductPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProductdicPrice(event.target.value);
  };

  const handleSkuid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSKUID(event.target.value);
  };

  const categories: string[] = [
    "Luxury",
    "Apparel",
    "Organic",
    "Healthcare",
    "Liquor",
    "Art",
    "Industrial Equipment",
    "Electronics",
    "Home and Kitchen",
    "Beauty and Personal Care",
    "Sports and Outdoors",
    "Automotive",
    "Books",
    "Toys and Games",
    "Grocery and Gourmet Food",
    "Baby Products",
    "Pet Supplies",
    "Office Products",
    "Music",
    "Movies and TV",
    "Garden and Outdoor",
    "Health and Household",
    "Computers",
    "Cell Phones and Accessories",
    "Jewelry",
    "Shoes",
    "Handmade Products",
    "Tools and Home Improvement",
    "Musical Instruments",
    "Video Games",
    "Software",
    "Appliances",
    "Furniture",
    "Crafts and Sewing",
    "Industrial and Scientific",
    "Luggage and Travel Gear",
    "Office Furniture",
    "Educational Supplies",
    "Safety and Security",
    "Collectibles and Fine Art",
    "Real Estate",
    "Fashion",
    "Financial Services",
    "Insurance",
    "Medical Supplies",
    "Construction Materials",
    "Energy and Utilities",
    "Telecommunications",
    "Transportation",
    "Food and Beverages",
    "Media and Entertainment",
    "Consulting Services",
    "Legal Services",
    "Retail",
    "Hospitality and Travel",
    "Nonprofit Organizations",
    "Government Services",
    "Aerospace",
    "Agriculture",
    "Pharmaceuticals",
    "Biotechnology",
    "Marine",
    "Defense",
    "Mining",
    "Others",
  ];
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const socialMediaIcons: any = {
    Instagram: Instagramlogo,
    Website: Websitelogo,
    LinkedIn: LinkedInlogo,
    Facebook: Facebooklogo,
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    setIsDropdownOpen(false);
  };

  const addKeyBenefit = () => {
    if (keyBenefits.length < 6) {
      setKeyBenefits([...keyBenefits, { id: Date.now(), value: "" }]);
    } else {
      toast.error("You can only add up to 4 key benefits.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const deleteKeyBenefit = (id: number) => {
    setKeyBenefits(keyBenefits.filter((benefit) => benefit.id !== id));
  };

  const handleBenefitChange = (id: number, value: string) => {
    const updatedBenefits = keyBenefits.map((benefit) =>
      benefit.id === id ? { ...benefit, value: value } : benefit
    );
    setKeyBenefits(updatedBenefits);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageUrls((prevUrls) => {
        const nextIndex = (currentImage + 1) % prevUrls.length;
        setCurrentImage(nextIndex);
        return prevUrls;
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentImage]);

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 1 ? 2 : 1));
    }, 5000); // Switch every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  const originalprice = Number(productPrice);
  const discountprise = Number(productPrice) - Number(productdicPrice);

  const handlebackward = () => {
    navigate(-1);
  };

  const preventTyping = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
  };
  const preventinputTyping = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    const productTenant = localStorage.getItem("tenant");
    if (productTenant === `morepen1` || productTenant=="pmbi-morepen1") {
      setShowInputs(true);
    }
  }, []);

 

  return (
    <div className="forms">
      <ToastContainer />
      <div className="forms-header">
        <div className="forms-text">
          <p>Edit Products</p>
        </div>
      </div>
      <div className="form-container">
        <div className="form-left">
          <input
            type="text"
            placeholder={editdatas?.title}
            className="custom-input"
            value={productName}
            onChange={handleProductNameChange}
            style={{ cursor: "not-allowed" }}
            readOnly
          />
          <textarea
            placeholder={editdatas?.description}
            className="custom-textarea"
            value={productDescription}
            onChange={handleProductDescriptionChange}
            maxLength={120}
          />
          <textarea
            placeholder={`Add Product Tagline here.${
              type === "type2" ? "" : "🔒"
            }`}
            className={`custom-textarea ${textareaClass}`}
            onKeyDown={type === "type2" ? undefined : preventTyping}
            maxLength={50}
            value={tagline}
            onChange={handleProducttaglineChange}
          />
          <input
            type="text"
            placeholder="SKU ID"
            className="custom-sku-id"
            value={id}
            onChange={handleSkuid}
            style={{ cursor: "not-allowed" }}
            readOnly
            maxLength={20}
          />
          <div className="morepen-form">
          {showInputs && (
        <div style={{ display: "flex", flexDirection: "column", gap: "0px" }}>
          {/* <p className="quantity-header">
              Manufacturing Date
            </p>
            <InputMask
            mask="99/99/9999"
            placeholder="DD/MM/YYYY"
            value={manufacturingDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setManufacturingDate(e.target.value)}
            style={{ width: "100px", padding: "5px", fontFamily: "monospace", backgroundColor: "transparent", border: "none", borderBottom: "0.5px solid #0956cc", outline: "none" }}
          />
          <p className="quantity-header">Expire Date</p>
          <InputMask
            mask="99/99/9999"
            placeholder="DD/MM/YYYY"
            value={expiryDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setExpiryDate(e.target.value)}
            style={{ width: "100px", padding: "5px", fontFamily: "monospace", backgroundColor: "transparent", border: "none", borderBottom: "0.5px solid #0956cc", outline: "none" }}
          />
          <p className="quantity-header">Batch Number</p>
          <input
            className="morepen_input"
            type="text"
            placeholder="Batch Number"
            value={batchNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBatchNumber(e.target.value)}
            style={{ width: "150px", padding: "5px", border: "none", borderBottom: "0.5px solid #0956cc", backgroundColor: "transparent" }}
          /> */}
          <p className="quantity-header">Janaushadhi Code</p>
          <input
            className="morepen_input"
            type="text"
            placeholder="Janaushadhi Code"
            value={janaushadhiCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJanaushadhiCode(e.target.value)}
            style={{ width: "150px", padding: "5px", border: "none", borderBottom: "0.5px solid #0956cc", backgroundColor: "transparent" }}
          />
        </div>
      )}
    </div>
          <div className="form-custom-dropdown">
            <div className="form-dropdown-header" onClick={toggleDropdown}>
              {selectedItem}
              <img
                src={downArrow}
                alt="Down arrow"
                className="form-down-arrow"
              />
            </div>
            {isDropdownOpen && (
              <div className="form-dropdown-list">
                {categories.map((category, index) => (
                  <div
                    key={index}
                    className="form-dropdown-item"
                    onClick={() => handleItemClick(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="quantity-section">
            <p className="quantity-header">
              Quantity {type === "type2" ? "" : "🔒"}
            </p>
            <div className="quantity-input-container">
              <input
                type="number"
                className={`quantity-input ${textareaClass}`}
                onKeyDown={type === "type2" ? undefined : preventinputTyping}
                placeholder="Add weight"
              />
              <select className="quantity-select">
                <option value="kg">kg</option>
                <option value="gm">gm</option>
                <option value="lbs">lbs</option>
                <option value="oz">oz</option>
              </select>
            </div>
          </div>
          <div className="price-section">
            <div className="price-and-checkboxes">
              <p>Price {type === "type2" ? "" : "🔒"}</p>
              {/* <div className="checkboxes-container">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange("PriceSealed", e.target.checked)
                      }
                      checked={checkboxOptions["PriceSealed"] || false}
                    />
                    <span className="checkbox-label">Sealed</span>
                  </label>
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) =>
                        handleCheckboxChange("PriceUnsealed", e.target.checked)
                      }
                      checked={checkboxOptions["PriceUnsealed"] || false}
                    />
                    <span className="checkbox-label">Unsealed</span>
                  </label>
                </div> */}
            </div>
            <div className="form-price-discount">
              <div className="input-container">
                <span className="currency-symbol">₹</span>
                <input
                  type="text"
                  className={`currency-input ${textareaClass}`}
                  onKeyDown={type === "type2" ? undefined : preventinputTyping}
                  placeholder="Product Price"
                  value={productPrice}
                  onChange={handleProductPriceChange}
                />
              </div>
              <div className="input-container">
                <span className="currency-symbol">₹</span>
                <input
                  type="text"
                  className={`currency-input ${textareaClass}`}
                  onKeyDown={type === "type2" ? undefined : preventinputTyping}
                  value={productdicPrice}
                  onChange={handleDisProductPriceChange}
                  placeholder="Discount Price"
                />
              </div>
            </div>
          </div>
          <div className="form-images-upload-section">
            <div className="form-images-text">
              <p>Photos</p>
            </div>
            <div style={{ display: "flex" }}>
              <div {...getRootProps()} className="form-images-upload">
                <input {...getInputProps()} id="file-upload" />
                <label htmlFor="file-upload" className="file-label">
                  <div className="file-button">+</div>
                </label>
              </div>

              <div
                className="images-preview-container"
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  height: "100px",
                  width: "100px",
                  marginTop: "10px",
                }}
              >
                {imageUrls.map((url, index) => (
                  <>
                    <img
                      key={index}
                      src={url}
                      alt={`Selected ${index + 1}`}
                      style={{
                        width: "60px",
                        height: "60px",
                        marginLeft: "10px",
                      }}
                    />
                    <button
                      className="remove-btn"
                      style={{
                        borderRadius: "50%",
                        cursor: "pointer",
                        height: "25px",
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                      }}
                      onClick={() => removePhoto(index)}
                    >
                      &#10005; {/* Unicode for cross icon */}
                    </button>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="form-keybenefits-section">
            <div className="keybenefit-and-checkboxes">
              <p>Key Benefits</p>
              {/* <div className="checkboxes-container">
                <label className="checkbox-container">
                <input type="checkbox" className="form-checkbox"
                    onChange={(e) =>
                      handleCheckboxChange("KeySealed", e.target.checked)
                    }
                    checked={checkboxOptions["KeySealed"] || false} />
                  <span className="checkbox-label">Sealed</span>
                </label>
                <label className="checkbox-container">
                  <input type="checkbox" className="form-checkbox"
                    onChange={(e) =>
                      handleCheckboxChange("KeyUnsealed", e.target.checked)
                    }
                    checked={checkboxOptions["KeyUnsealed"] || false} />
                  <span className="checkbox-label">Unsealed</span>
                </label>
              </div> */}
            </div>
            <span className="limiter-texts">(Add upto 6 Key Benefits)</span>
            {keyBenefits.map((benefit, index) => (
              <div key={benefit.id} className="keybenefit-input-container">
                <input
                  type="text"
                  placeholder="Add Key Benefit here"
                  className="keybenefit-input"
                  value={benefit.value}
                  maxLength={50}
                  onChange={(e) =>
                    handleBenefitChange(benefit.id, e.target.value)
                  }
                />
                <div className="keybenefit-signs-container">
                  {index !== 0 && (
                    <div className="keybenefit-signs-box">
                      <div
                        className="keybenefit-minus-icon"
                        onClick={() => deleteKeyBenefit(benefit.id)}
                      >
                        -
                      </div>
                    </div>
                  )}
                  <div className="keybenefit-signs-box">
                    <div
                      className={`keybenefit-add-icon ${
                        keyBenefits.length >= 6 ? "disabled" : ""
                      }`}
                      onClick={addKeyBenefit}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="video-links-section">
            <div className="video-links-checkboxes">
              <p>Video's Link</p>
              {/* <div className="checkboxes-container">
                <label className="checkbox-container">
                <input type="checkbox" className="form-checkbox"
                   onChange={(e) =>
                    handleCheckboxChange("VideoSealed", e.target.checked)
                  }
                  checked={checkboxOptions["VideoSealed"] || false}  />
                  <span className="checkbox-label">Sealed</span>
                </label>
                <label className="checkbox-container">
                  <input type="checkbox" className="form-checkbox"
                   onChange={(e) =>
                    handleCheckboxChange("VideoUnsealed", e.target.checked)
                  }
                  checked={checkboxOptions["VideoUnsealed"] || false}  />
                  <span className="checkbox-label">Unsealed</span>
                </label>
              </div> */}
            </div>
            <span className="limiter-texts">(Add upto 4 video links)</span>
            {videoLinks.map((link, index) => (
              <div key={link.id} className="video-link-input-container">
                <input
                  type="text"
                  placeholder="Add Youtube, Vimeo or other links"
                  className="keybenefit-input"
                  value={link.value}
                  onChange={(e) =>
                    handleVideoLinkChange(link.id, e.target.value)
                  }
                />
                <div className="video-link-signs-container">
                  {index !== 0 && (
                    <div className="video-link-signs-box">
                      <div
                        className="video-link-minus-icon"
                        onClick={() => deleteVideoLink(link.id)}
                      >
                        -
                      </div>
                    </div>
                  )}
                  <div className="video-link-signs-box">
                    <div
                      className={`video-link-add-icon ${
                        videoLinks.length >= 4 ? "disabled" : ""
                      }`}
                      onClick={addVideoLink}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <div className="nutrition-section">
              <p className="nutrition-header">{`Specification ${
                type === "type2" ? "" : "🔒"
              }`}</p>
              {/* <div className="nutrition-amount-container">
                <input
                  type="number"
                  className={`nutrition-amount-input ${textareaClass}`}
                  placeholder="Amount"
                  onKeyDown={type === "type2" ? undefined : preventinputTyping}

                />
                <select className="nutrition-unit-select">
                  <option value="g">g</option>
                  <option value="mg">mg</option>
                </select>
              </div> */}
              {nutritionItems.map((item, index) => (
                <div className="nutrition-item-container" key={index}>
                  <input
                    type="text"
                    className={`nutrition-name-input ${textareaClass}`}
                    placeholder="Nutrition"
                    value={item.name}
                    onKeyDown={
                      type === "type2" ? undefined : preventinputTyping
                    }
                    onChange={(e) =>
                      handleNutritionChange(index, "name", e.target.value)
                    }
                  />
                  <input
                    type="text"
                    className={`nutrition-value-input ${textareaClass}`}
                    placeholder="Value"
                    value={item.value}
                    onKeyDown={
                      type === "type2" ? undefined : preventinputTyping
                    }
                    onChange={(e) =>
                      handleNutritionChange(index, "value", e.target.value)
                    }
                  />
                  <select
                    className="nutrition-unit-select"
                    value={item.unit}
                    onChange={(e) =>
                      handleNutritionChange(index, "unit", e.target.value)
                    }
                  >
                    <option value="g">g</option>
                    <option value="mg">mg</option>
                  </select>
                  {index === 0 && (
                    <div
                      onClick={handleAddNutritionItem}
                      className="add-nutrition-item-button"
                    >
                      +
                    </div>
                  )}
                  {index !== 0 && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        onClick={() => handleRemoveNutritionItem(index)}
                        className="remove-nutrition-item-button"
                      >
                        -
                      </div>
                      <div
                        onClick={handleAddNutritionItem}
                        className="add-nutrition-item-button"
                      >
                        +
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="ingredient-section">
              <p className="ingredient-header">{`What is in the package? ${
                type === "type2" ? "" : "🔒"
              }`}</p>
              <textarea
                placeholder="Add Product Ingredients here."
                className={`custom-textarea ${textareaClass}`}
                // onKeyDown={preventTyping}
                value={packagetext}
                onChange={handlePackageChange}
                maxLength={800}
              />
            </div>

            <div className="direction-to-use-section">
              <div className="direction-to-use-text">
                <p>{`Direction to Use ${type === "type2" ? "" : "🔒"}`}</p>
              </div>
              {imageTextData.map((data, index) => (
                <div key={index} className="image-selector">
                  {!data.image && (
                    <button
                      className={textareaClass}
                      onClick={() => handleImageSelectorClick(index)}
                    >
                      Select Image
                    </button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleFileChange(event, index)}
                    ref={(ref) => (fileInputRefs.current[index] = ref)}
                    style={{ display: "none" }}
                    className={textareaClass}
                    onKeyDown={
                      type === "type2" ? undefined : preventinputTyping
                    }
                  />
                  {data.image && (
                    <div className="image-container">
                      <img
                        src={data.image}
                        alt="Selected Image"
                        className={textareaClass}
                      />
                    </div>
                  )}
                  <div className="textarea-container">
                    <textarea
                      className={`direction-use-textarea ${textareaClass}`}
                      // onKeyDown={
                      //   type === "type2" ? undefined : preventinputTyping
                      // }
                      placeholder="Enter text here"
                      value={data.text}
                      onChange={(event) => handleTextareaChange(event, index)}
                    ></textarea>
                  </div>
                  <div className="direction-button-container">
                    {index !== 0 && (
                      <div
                        className="remove-direction-item-button"
                        onClick={() => handleRemove(index)}
                      >
                        -
                      </div>
                    )}
                    <div
                      className="add-direction-item-button"
                      onClick={handleAddNew}
                    >
                      +
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="sustainability-section">
              <div className="sustainability-text">
                <p>{`Sustainability ${type === "type2" ? "" : "🔒"}`}</p>
              </div>
              {sustainabilityData.map((data, index) => (
                <div key={index} className="image-selector">
                  {!data.image && (
                    <button
                      onClick={() =>
                        handleSustainabilityImageSelectorClick(index)
                      }
                      className={textareaClass}
                    >
                      Select Certificate
                    </button>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      handleSustainabilityFileChange(event, index)
                    }
                    className={textareaClass}
                    onKeyDown={
                      type === "type2" ? undefined : preventinputTyping
                    }
                    ref={(ref) =>
                      (sustainabilityFileInputRefs.current[index] = ref)
                    }
                    style={{ display: "none" }}
                  />
                  {data.image && (
                    <div className="sustainability-image-container">
                      <img
                        src={data.image}
                        alt="Selected Image"
                        className={textareaClass}
                      />
                    </div>
                  )}
                  <div className="textarea-container">
                    <textarea
                      className={`sustainability-textarea ${textareaClass}`}
                      placeholder="Sustainability Message"
                      onKeyDown={type === "type2" ? undefined : preventTyping}
                      value={data.text1}
                      onChange={(event) =>
                        handleSustainabilityTextareaChange1(event, index)
                      }
                    ></textarea>
                    <textarea
                      className={`sustainability-textarea ${textareaClass}`}
                      placeholder="Sustainability Practice"
                      onKeyDown={type === "type2" ? undefined : preventTyping}
                      value={data.text2}
                      onChange={(event) =>
                        handleSustainabilityTextareaChange2(event, index)
                      }
                    ></textarea>
                  </div>
                  <div className="sustainability-button-container">
                    {index !== 0 && (
                      <div
                        className="remove-direction-item-button"
                        onClick={() => handleSustainabilityRemove(index)}
                      >
                        -
                      </div>
                    )}
                    <div
                      className="add-direction-item-button"
                      onClick={handleSustainabilityAddNew}
                    >
                      +
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="social-media-section">
              <div>
                <span className="social-media-header">{`Social Media Links ${
                  type === "type2" ? "" : "🔒"
                }`}</span>
              </div>
              <span className="limiter-texts">
                (Add upto 4 Social Media Links)
              </span>
              {socialMediaLinks.map((link, index) => (
                <div key={link.id} className="social-media-input-container">
                  <div className="social-media-input-wrapper">
                    <select
                      className={`social-media-select ${textareaClass}`}
                      value={link.platform}
                      onChange={(e) =>
                        handleSocialMediaChange(
                          link.id,
                          "platform",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Social Media</option>

                      {getAvailableSocialMediaOptions().map((option) => (
                        <option key={option.platform} value={option.platform}>
                          {option.platform}
                        </option>
                      ))}
                    </select>
                    {link.platform && (
                      <img
                        src={
                          socialMediaOptions.find(
                            (option) => option.platform === link.platform
                          )?.icon
                        }
                        alt={`${link.platform} icon`}
                        className="social-icon"
                      />
                    )}
                    <input
                      type="text"
                      className={`social-media-input ${textareaClass}`}
                      placeholder={`Enter ${link.platform} link`}
                      value={link.url}
                      onChange={(e) =>
                        handleSocialMediaChange(link.id, "url", e.target.value)
                      }
                      onKeyDown={
                        type === "type2" ? undefined : preventinputTyping
                      }
                    />
                    {index !== 0 && (
                      <button
                        onClick={() => deleteSocialMediaLink(link.id)}
                        className="remove-direction-item-button"
                      >
                        -
                      </button>
                    )}
                    {index === socialMediaLinks.length - 1 &&
                      socialMediaLinks.length < 4 && (
                        <button
                          onClick={addSocialMediaLink}
                          className="add-direction-item-button"
                        >
                          +
                        </button>
                      )}
                  </div>
                </div>
              ))}
            </div>

            <div className="brand-story-checkboxes">
              <p>Brand Story</p>
              {/* <div className="checkboxes-container">
                <label className="checkbox-container">
                <input type="checkbox" className="form-checkbox" 
                     onChange={(e) =>
                      handleCheckboxChange("BrandSealed", e.target.checked)
                    }
                    checked={checkboxOptions["BrandSealed"] || false} />
                  <span className="checkbox-label">Sealed</span>
                </label>
                <label className="checkbox-container">
                  <input type="checkbox" className="form-checkbox"    onChange={(e) =>
                    handleCheckboxChange("BrandUnsealed", e.target.checked)
                  }
                  checked={checkboxOptions["BrandUnsealed"] || false}  />
                  <span className="checkbox-label">Unsealed</span>
                </label>
              </div> */}
            </div>
            <div>
              <textarea
                placeholder="Add Brand Story"
                className="brand-story-textarea"
                value={brandStory}
                onChange={handleBrandStoryChange}
                maxLength={400}
              />
            </div>
          </div>
          <div>
            <div className="brand-mission-checkboxes">
              <p>Brand Mission</p>
              {/* <div className="checkboxes-container">
                <label className="checkbox-container">
                <input type="checkbox" className="form-checkbox" 
                  onChange={(e) =>
                    handleCheckboxChange("MissionSealed", e.target.checked)
                  }
                  checked={checkboxOptions["MissionSealed"] || false} />
                  <span className="checkbox-label">Sealed</span>
                </label>
                <label className="checkbox-container">
                  <input type="checkbox" className="form-checkbox" 
                  onChange={(e) =>
                    handleCheckboxChange("MissionUnsealed", e.target.checked)
                  }
                  checked={checkboxOptions["MissionUnsealed"] || false} />
                  <span className="checkbox-label">Unsealed</span>
                </label>
              </div> */}
            </div>
            <div>
              <textarea
                placeholder="Add Brand Mission"
                className="brand-mission-textarea"
                value={brandMission}
                onChange={handleBrandMissionChange}
                maxLength={400}
              />
            </div>
          </div>

          <div className="price-section">
            <div className="price-and-checkboxes">
              <p>{`Button 1 ${type === "type2" ? "" : "🔒"}`}</p>
            </div>
            <div className="form-price-discount">
              <div className="input-container">
                <span className="currency-symbol">Text:</span>
                <input
                  type="text"
                  style={{ marginLeft: "35px" }}
                  className={`currency-input ${textareaClass}`}
                  placeholder="Button1 Text"
                  value={button1text || ""}
                  onChange={(e) => setButton1text(e.target.value)}
                />
              </div>
              <div className="input-container">
                <span className="currency-symbol">URL:</span>
                <input
                  type="text"
                  className={`currency-input ${textareaClass}`}
                  onKeyDown={type === "type2" ? undefined : preventinputTyping}
                  style={{ marginLeft: "35px" }}
                  value={button1URL || ""}
                  onChange={(e) => setButton1URL(e.target.value)}
                  placeholder="Button1 URL"
                />
              </div>
            </div>
          </div>
          <div className="price-section">
            <div className="price-and-checkboxes">
              <p> {`Button 2 ${type === "type2" ? "" : "🔒"}`}</p>
            </div>
            <div className="form-price-discount">
              <div className="input-container">
                <span className="currency-symbol">Text:</span>
                <input
                  type="text"
                  style={{ marginLeft: "35px" }}
                  className={`currency-input ${textareaClass}`}
                  onKeyDown={type === "type2" ? undefined : preventinputTyping}
                  placeholder="Button2 Text"
                  value={button2text || ""}
                  onChange={(e) => setButton2text(e.target.value)}
                />
              </div>
              <div className="input-container">
                <span className="currency-symbol">URL:</span>
                <input
                  type="text"
                  style={{ marginLeft: "35px" }}
                  className={`currency-input ${textareaClass}`}
                  onKeyDown={type === "type2" ? undefined : preventinputTyping}
                  value={button2URL || ""}
                  onChange={(e) => setButton2URL(e.target.value)}
                  placeholder="Button2 URL"
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "10px", width: "100%" }}>
            <p onClick={customize} className="customize-form-text">
              {customizeing ? (
                <>
                  Close Customize
                  <img
                    src={Closedcustomize}
                    alt="Close Customize"
                    style={{ marginLeft: "5px", height: "7px" }}
                  />
                </>
              ) : (
                <>
                  Customize Theme
                  <img
                    src={customizeImage}
                    alt="Customize Theme"
                    style={{ marginLeft: "5px", height: "7px" }}
                  />
                </>
              )}
            </p>
            {customizeing ? (
              <div className="color-picker">
                <p>{`Choose Color: ${type === "type2" ? "" : "🔒"}`}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className={textareaClass} style={{ margin: "10px" }}>
                    <p>For Background</p>
                    <SwatchesPicker
                      color={color}
                      onChange={handleColorChange}
                    />
                  </div>
                  <div className={textareaClass} style={{ margin: "10px" }}>
                    <p>For Heading</p>
                    <SwatchesPicker
                      color={headingcolor}
                      onChange={handleheaingColorChange}
                    />
                  </div>
                  <div className={textareaClass} style={{ margin: "10px" }}>
                    <p>For Text</p>
                    <SwatchesPicker
                      color={textcolor}
                      onChange={handleTextColorChange}
                    />
                  </div>
                </div>
                {/* <div
                  style={{ backgroundColor: color, width: "30px", height: "30px", marginTop: '10px' }}
                ></div> */}
              </div>
            ) : null}
          </div>

          <div className="form-submit-button">
            <button className="submit-button" onClick={handleSubmit}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
            <button onClick={handlebackward} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>

        <div className="form-right">
          <div
            style={{
              order: "1",
              marginBottom: "20px",
              maxWidth: "270px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                overflowY: "auto",
                background: "#fff",
                height: "calc(80vh - 30px)",
                borderRadius: "30px",
                overflow: "hidden",
                position: "relative",
                border: "2px solid #ddd",
                minWidth: "270px",
                maxWidth: "270px",
                backgroundColor: color,
                marginBottom: "40px",
              }}
            >
              <div
                style={{
                  background: "#000",
                  height: "20px",
                  borderRadius: "30px 30px 0 0",
                }}
              ></div>

              {/* iPhone Screen */}
              <br />

              <div
                className="scrollbarStyle"
                style={{
                  height: "calc(90vh - 170px)",
                  overflowY: "auto",
                  WebkitOverflowScrolling: "touch",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={convertedbrandlnk}
                    height={50}
                    width={50}
                    alt="brand logo"
                  />
                </div>
                <br></br>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {editdatas
                    ? editdatas.photo.length > 0 && (
                        <img
                          src={editdatas.photo[0]}
                          alt={`Uploaded Image ${currentImage + 1}`}
                          height={"auto"}
                          width={"100%"}
                        />
                      )
                    : null}
                </div>

                <div style={{ padding: "20px" }}>
                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: headingcolor,
                      wordWrap: "break-word",
                      overflow: "hidden", // or 'auto' if you want scrollbars
                      maxWidth: "100%",
                    }}
                  >
                    {productName}
                  </h3>

                  <h5
                    style={{
                      fontSize: "12px",
                      fontWeight: "700",
                      color: headingcolor,
                      marginBottom: "20px",
                      wordWrap: "break-word",
                      overflow: "hidden", // or 'auto' if you want scrollbars
                      maxWidth: "100%",
                    }}
                  >
                    {tagline}
                  </h5>

                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "12px",
                      fontWeight: "400px",
                      color: textcolor,
                      textAlign: "justify",
                      wordWrap: "break-word",
                      overflow: "hidden",
                      maxWidth: "100%",
                    }}
                  >
                    {productDescription}
                  </div>

                   <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: headingcolor,
                      display:'flex',
                      marginBottom: "20px",
                    }}
                  >
                    ₹ {discountprise} <div style={{textDecoration: 'line-through' ,marginLeft:'2px'}}>
                    {Number(productdicPrice)!=0?`  ${productdicPrice}`:null}</div> 
                  </p> 

                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    {button1text.length != "" ? (
                      <button
                        style={{
                          fontSize: "10px",
                          padding: "15px",
                          backgroundColor: "#0956cc",
                          border: "none",
                          minWidth: "90px",
                          borderRadius: "4px",
                          color: "white",
                        }}
                        className="btn-outline"
                        onClick={() => window.open(button1URL, "_blank")}
                      >
                        {button1text || "Locate Store"}
                      </button>
                    ) : null}
                    {button2text.length != "" ? (
                      <button
                        style={{
                          fontSize: "10px",
                          padding: "15px",
                          backgroundColor: "white",
                          border: "1px solid blue",
                          minWidth: "90px",
                          borderRadius: "4px",
                        }}
                        onClick={() => window.open(button2URL, "_blank")}
                        className="btn-contained"
                      >
                        {button2text || `Brand's Website`}
                      </button>
                    ) : null}
                  </div>
                  <br />

                  {/* <h3
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      color: "black",
                      marginBottom: "20px",
                    }}
                  >
                    Product Journey
                  </h3>
 
                  <br /> */}
                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      color: headingcolor,
                      marginBottom: "20px",
                    }}
                  >
                    {packagetext !== "" ? "Ingredients" : null}
                  </h3>

                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "12px",
                      fontWeight: "400", // Note: This should be 'fontWeight', not 'fontWeightpx'
                      color: textcolor,
                      textAlign: "justify",
                      wordWrap: "break-word",
                      overflow: "hidden", // or 'auto' if you want scrollbars
                      maxWidth: "100%",
                    }}
                  >
                    {packagetext}
                  </div>
                  <br />

                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      color: headingcolor,
                      marginBottom: "10px",
                    }}
                  >
                    {nutritionItems.length > 1 ? "Nutrition" : null}
                  </p>
                  {nutritionItems.map((benefit, index) => (
                    <div
                      key={index}
                      className="mb-4"
                      style={{
                        paddingBottom: "3px",
                        display: "inline",
                        color: textcolor,
                        fontSize: "12px",
                      }}
                    >
                      <p>
                        {nutritionItems.length > 1
                          ? `• ${benefit.name} (${benefit.unit}) ${benefit.value}`
                          : null}
                      </p>
                    </div>
                  ))}

                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      color: headingcolor,
                      marginBottom: "20px",
                    }}
                  >
                    {brandMission.length > 0 ? " Our Mission" : null}
                  </h3>

                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "12px",
                      fontWeight: "400", // Note: This should be 'fontWeight', not 'fontWeightpx'
                      color: textcolor,
                      textAlign: "justify",
                      wordWrap: "break-word",
                      overflow: "hidden", // or 'auto' if you want scrollbars
                      maxWidth: "100%",
                    }}
                  >
                    {brandMission}
                  </div>

                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      color: headingcolor,
                      marginBottom: "20px",
                    }}
                  >
                    {keyBenefits.length > 1 ? "Key Benefits" : null}
                  </p>
                  {keyBenefits.map((benefit, index) => (
                    <div
                      key={benefit.id}
                      className="mb-4"
                      style={{
                        paddingBottom: "3px",
                        display: "inline",
                        fontSize: "12px",
                        color: textcolor,
                      }}
                    >
                      <p>
                        {keyBenefits.length > 1
                          ? `${index + 1}. ${benefit.value}`
                          : null}
                      </p>
                    </div>
                  ))}

                  <h3
                    style={{
                      fontSize: "14px",
                      fontWeight: "800",
                      color: headingcolor,
                      marginBottom: "20px",
                    }}
                  >
                    {brandStory.length > 0 ? `Our Story` : null}
                  </h3>

                  <div
                    style={{
                      marginBottom: "20px",
                      fontSize: "12px",
                      fontWeight: "400", // Note: This should be 'fontWeight', not 'fontWeightpx'
                      color: textcolor,
                      textAlign: "justify",
                      wordWrap: "break-word",
                      overflow: "hidden", // or 'auto' if you want scrollbars
                      maxWidth: "100%",
                    }}
                  >
                    {brandStory}
                  </div>
                  <br />
                </div>

                <div style={{ backgroundColor: "black", height: "130px" }}>
                  <div className="powerSourceParent">
                    <div className="powerSource" style={{ margin: "7px" }}>
                      <img
                        className="greenCopy1"
                        loading="eager"
                        alt=""
                        src={arvologo}
                      />
                    </div>
                    <div
                      className="privacyPolicyContactUs"
                      style={{ margin: "7px" }}
                    >
                      <div className="privacyPolicy">Rate this Product</div>
                      {/* <div className={styles.privacyPolicy} onClick={togglePopup2}>Raise Issue</div> */}
                    </div>
                  </div>
                  <div className="dividerLine" />
                  <div className="footerInfo">
                    <div className="poweredByArvo" style={{ margin: "7px" }}>
                      Powered by ARVO © 2024
                    </div>

                    {socialMediaLinks.length > 1 && (
                      <div>
                        {socialMediaLinks.map((link) => (
                          <a
                            key={link.id}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="socialMediaLink"

                          >
                            <img
                              src={socialMediaIcons[link.platform]}
                              alt={link.platform}
                              className="socialMediaIcon"
                            />
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* iPhone Bottom Navigation Bar */}
              <div
                style={{
                  background: "#f0f0f0",
                  height: "40px",
                  borderRadius: "0 0 30px 30px",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  zIndex: "10",
                }}
              >
                <div
                  style={{
                    background: "#bbb",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    background: "#bbb",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                  }}
                ></div>
                <div
                  style={{
                    background: "#bbb",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formedit;
