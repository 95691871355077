import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify"; // 1) Import Toastify
import "react-toastify/dist/ReactToastify.css";         // 2) Import Toastify CSS
import "./MorepenManualEntry.css";

function MorepenManualEntry() {
  const [batchID, setBatchID] = useState("");
  const [grmNumber, setGrmNumber] = useState(""); // New state for GRM Number
  const [expiryDate, setExpiryDate] = useState("");
  const [manufactureDate, setManufactureDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 3) Validate dates
    const expiry = new Date(expiryDate);
    const manufacture = new Date(manufactureDate);
    
    // If expiry date is strictly before manufacturing date
    if (expiry < manufacture) {
      toast.error("Expiry date cannot be earlier than manufacturing date");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://w2d1g7akg3.execute-api.ap-south-1.amazonaws.com/dev/manualentry",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            batchID,
            grmNumber, // Send GRM Number to the API
            expiryDate,
            manufactureDate,
          }),
        }
      );

      if (response.ok) {
        // 4) Show success message with Toastify
        toast.success("Batch created successfully!");
        // Clear form fields
        setBatchID("");
        setGrmNumber(""); // Clear GRM Number field
        setExpiryDate("");
        setManufactureDate("");
      } else {
        // 5) Show error message with Toastify
        console.error("Error updating item data:", response.status);
        toast.error("Failed to update data. Please try again.");
      }
    } catch (err) {
      // 6) Show error message with Toastify
      console.error("Network or request error:", err);
      toast.error("An error occurred. Please check your network or try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="morepen-container">
      <h2 className="morepen-title">Create Batch</h2>
      <form onSubmit={handleSubmit} className="morepen-form">
        {/* Batch ID */}
        <div className="form-group">
          <label htmlFor="batchID" className="form-label">
            Batch ID
          </label>
          <input
            id="batchID"
            type="text"
            value={batchID}
            onChange={(e) => setBatchID(e.target.value)}
            required
            className="form-input"
            placeholder="Enter batch ID"
          />
        </div>

        {/* GRM Number */}
        <div className="form-group">
          <label htmlFor="grmNumber" className="form-label">
            GRM Number
          </label>
          <input
            id="grmNumber"
            type="text"
            value={grmNumber}
            onChange={(e) => setGrmNumber(e.target.value)}
            required
            className="form-input"
            placeholder="Enter GRM Number"
          />
        </div>

        {/* Expiry Date */}
        <div className="form-group">
          <label htmlFor="expiryDate" className="form-label">
            Expiry Date
          </label>
          <input
            id="expiryDate"
            type="date"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            required
            className="form-input"
          />
        </div>

        {/* Manufacture Date */}
        <div className="form-group">
          <label htmlFor="manufactureDate" className="form-label">
            Manufacture Date
          </label>
          <input
            id="manufactureDate"
            type="date"
            value={manufactureDate}
            onChange={(e) => setManufactureDate(e.target.value)}
            required
            className="form-input"
          />
        </div>

        {/* Submit Button */}
        <div className="form-group-button">
          <button type="submit" className="submit-button" disabled={isLoading}>
            {isLoading ? "Creating..." : "Create Batch"}
          </button>
        </div>
      </form>

      {/* 7) Toast Container allows Toastify to render the notifications */}
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}

export default MorepenManualEntry;
