import React, { useState, useEffect, useRef } from "react";
import "./Counterfeits.css";
import ReloadIcon from "../assets/reload.png";
import filterIcon from "../assets/filter.png";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchNfcSessions,
  fetchProducts,
} from "../redux/counterSlice";

/**
 * Replaces your existing Counterfeits component
 * to display a multi-row header table like the snippet
 * you shared.
 */
function Counterfeits() {
  const [expandedRow, setExpandedRow] = useState(null);
  const tenant = localStorage.getItem("tenant")?.toLowerCase() || "";
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch fetchNfcSessions action when the component mounts
    dispatch(fetchNfcSessions(tenant));
    dispatch(fetchProducts(tenant));
  }, [dispatch, tenant]);

  // Toggle row expansion if you need it – not used in the new table
  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  // Access your Redux state
  const nfcsessions = useSelector((state) => state.counter.nfcsessions);

  // Sort by timeline ascending
  const sortedData = nfcsessions.slice().sort((a, b) => {
    return parseInt(a.timeline) - parseInt(b.timeline);
  });

  // Group data by Tagid
  const groupedData = {};
  sortedData.forEach((item) => {
    if (!groupedData[item.Tagid]) {
      groupedData[item.Tagid] = [item];
    } else {
      groupedData[item.Tagid].push(item);
    }
  });

  // Determine which groups have "decreasing decimalValue"
  const filteredTagIds = {};
  for (const tagId in groupedData) {
    const tagIdData = groupedData[tagId];
    const latestData = tagIdData[tagIdData.length - 1];

    let isCounterDecreasing = false;
    // Check previous records to see if decimalValue decreased
    for (let i = tagIdData.length - 2; i >= 0; i--) {
      const previousData = tagIdData[i];
      if (latestData.decimalValue < previousData.decimalValue) {
        isCounterDecreasing = true;
        break;
      }
    }

    if (isCounterDecreasing) {
      filteredTagIds[tagId] = tagIdData;
    }
  }

  // Helper to format date (YYYY-MM-DD)
  function sysmathsdDate(dates) {
    const time = Number(dates);
    const date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // zero-based
    const day = date.getDate();
    return `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
  }

  // Helper to format time (HH:MM:SS)
  function sysmathsdtime(dates) {
    const time = Number(dates);
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${hours}:${minutes}:${seconds}`;
  }

  // Convert filteredTagIds -> your final mapped array
  const mappedData = Object.entries(filteredTagIds).map(([key, value]) => {
    // key = Tagid
    // value = array of session objects for that Tagid
    return {
      tagId: key,
      objects: value.map((obj) => ({
        // "Last Scan Date" can be the latest timeline from this group
        // but here we store each item with:
        counterfeitDate: sysmathsdDate(obj.timeline),
        counterfeitTime: sysmathsdtime(obj.timeline),
        product: obj.title,
        itemID: obj.Tagid,
        location: obj.geodata?.city || "N/A",
        city: obj.geodata?.city || "N/A",
        region: obj.geodata?.region || "N/A",
        // numberOfScans could be how many times this Tagid was read
        // but if you want a single row per group, you might sum them
        // For demonstration, let's store timeline as "SKU"
        sku: obj.timeline, 
      })),
    };
  });

  // Now we want to create single rows that look like:
  // UID | numberOfScans | Last Scan Date | City | Region | ProductName | Part | ChallanNumber
  // We can define "UID" as item.tagId
  // "numberOfScans" as item.objects.length or some logic
  // "Last Scan Date" as the last object's date/time
  // city -> lastObj.city
  // region -> lastObj.region
  // product -> lastObj.product
  // part -> "N/A"
  // challanNumber -> "N/A"

  // Flatten the data so we have one row per TagId group
  const finalRows = mappedData.map((group) => {
    // "group" => { tagId: <string>, objects: <array> }
    // Let's define the "last" object as the most recent scan
    const lastObj = group.objects[group.objects.length - 1];

    // We'll compute the "number of scans" as group.objects.length
    const numberOfScans = group.objects.length;

    // We'll combine date + time if you want
    // or just store the date for "Last Scan Date"
    // For demonstration, let's show just the date
    return {
      uid: group.tagId,
      numberOfScans: numberOfScans,
      lastScanDate: `${lastObj.counterfeitDate} ${lastObj.counterfeitTime}`,
      city: lastObj.city,
      region: lastObj.region,
      productName: lastObj.product || "N/A",
      part: "N/A", // Hard-coded or from data if available
      chalanNumber: "N/A", // Hard-coded or from data if available
    };
  });

  return (
    <div className="orders">
      {/* Header */}
      <div className="orders-header">
        <div className="orders-text">
          <p>Suspicious Scans Report</p>
        </div>
        <div className="right-side-content">
          <span className="last-updated">Last updated 32 mins ago</span>
          <img src={ReloadIcon} alt="Reload" className="reload-icon" />
          <div className="order-filter">
            <button className="order-filter-button">
              <img src={filterIcon} alt="Filter" />
              Filter
            </button>
          </div>
        </div>
      </div>

      {/* Multi-Row Header Table */}
      <div className="counterfeit-report-container" style={{ marginTop: "20px" }}>
        {finalRows.length > 0 ? (
          <table className="counterfeit-report-table">
            <thead>
              <tr>
                <th rowSpan="2">UID</th>
                <th rowSpan="2">Number of Scans</th>
                <th rowSpan="2">Last Scan Date</th>
                <th colSpan="2">Last Scan Location</th>
                <th colSpan="3">Product Details</th>
              </tr>
              <tr>
                <th>City</th>
                <th>Region</th>
                <th>Product Name</th>
              
              </tr>
            </thead>
            <tbody>
              {finalRows.map((row, index) => (
                <tr key={index}>
                  <td>{row.uid}</td>
                  <td>{row.numberOfScans}</td>
                  <td>{row.lastScanDate}</td>
                  <td>{row.city}</td>
                  <td>{row.region}</td>
                  <td>{row.productName}</td>
               
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No counterfeit items found.</p>
        )}
      </div>
    </div>
  );
}

export default Counterfeits;
