import React, { useState, useRef, useEffect } from "react";
import Code from "../../../assets/rename.png";
import DropArrow from "../../../assets/dropdown.png";
import "./Scans.css";

function Scans({ nfcsessions }) {
  // ----------------------
  //  Prepare local data
  // ----------------------
  const datamin = nfcsessions.map((item) => {
    const scans = item.openclose;
    return { scans };
  });

  let breakCount = 0;
  let unbreakCount = 0;
  let notAttTagCount = 0;

  datamin.forEach((item) => {
    const { scans } = item;
    switch (scans) {
      case "break":
        breakCount++;
        break;
      case "unbreak":
        unbreakCount++;
        break;
      case "not a TT tag":
        notAttTagCount++;
        break;
      default:
        break;
    }
  });

  // Sum of break + unbreak = total scans on TT tags
  const totalScans = breakCount + unbreakCount;

  // ----------------------
  //  Component state
  // ----------------------
  const [scanType, setScanType] = useState("QR");
  const [showDropdown, setShowDropdown] = useState(false);

  // For scanning counts
  const [sealedScans, setSealedScans] = useState(unbreakCount);
  const [unsealedScans, setUnsealedScans] = useState(breakCount);
  const [sealedScansper, setSealedScansper] = useState(0);
  const [unsealedScansper, setUnsealedScansper] = useState(0);
  const [qrScansper, setQrScansper] = useState(0);

  const dropdownRef = useRef(null);

  // Holds the total codes generated (from /sum-qty API)
  const [totalGenerated, setTotalGenerated] = useState(0);

  // ----------------------
  //  Recalculate bars
  // ----------------------
  useEffect(() => {
    setSealedScans(unbreakCount);
    setUnsealedScans(breakCount);
    setSealedScansper((unbreakCount / (totalScans || 1)) * 100);
    setUnsealedScansper((breakCount / (totalScans || 1)) * 100);
    setQrScansper((notAttTagCount / ((breakCount + unbreakCount) || 1)) * 100);
  }, [nfcsessions, breakCount, unbreakCount, notAttTagCount, totalScans]);

  // ----------------------
  //  Fetch total codes if tenant=morepen1
  // ----------------------
  useEffect(() => {
    const tenant = localStorage.getItem("tenant");
    if (tenant === "morepen1" || tenant=="pmbi-morepen1" ) {
      fetch("https://w2d1g7akg3.execute-api.ap-south-1.amazonaws.com/dev/sum-qty")
        .then((res) => res.json())
        .then((data) => {
          if (data && data.totalProductQty) {
            setTotalGenerated(data.totalProductQty);
          }
        })
        .catch((err) =>
          console.error("Error fetching total product quantity:", err)
        );
    }
  }, []);

  // ----------------------
  //  Dropdown behavior
  // ----------------------
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const selectOption = (value) => {
    setScanType(value);
    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const perUnbreakWidth = (unbreakCount / (totalScans || 1)) * 100;
  const perBreakWidth = (breakCount / (totalScans || 1)) * 100;

  // If you have different subtypes or logic
  const type = localStorage.getItem("subtype");
  const redirectToPricing = () => {
    if (type === "type2") {
      // Possibly do something different here
    } else {
      // window.location.href = "/pricing";
    }
  };

  // ---------------
  //  PROGRESS BAR
  // ---------------
  /**
   * We'll treat totalGenerated as the "max" 
   * and totalScans as the "current" progress. 
   * If totalScans > totalGenerated, we clamp fill at 100%.
   */
  let progressPercentage = 0;
  if (totalGenerated > 0) {
    progressPercentage = (notAttTagCount / totalGenerated) * 100;
    if (progressPercentage > 100) {
      progressPercentage = 100;
    }
  }

  // ----------------------
  //  Render
  // ----------------------
  return (
    <div className="card card-two" onClick={redirectToPricing}>
      <div className="scanner-btn-align">
        <div className="top-container">
          <img src={Code} alt="Scan icon" className="total-scans-icon" />
          <div className="total-scans-label">Scans</div>
        </div>

        {/*
        If you want a scan-type dropdown, you can uncomment:

        <div className="dropdown" ref={dropdownRef}>
          <button
            className="dropdown-button"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            {scanType} <img src={DropArrow} alt="Dropdown arrow" />
          </button>
          {showDropdown && (
            <div className="dropdown-content">
              <button onClick={() => selectOption("QR")}>QR</button>
              <button onClick={() => selectOption("RFID")}>RFID</button>
            </div>
          )}
        </div>
        */}
      </div>

      {/*  If "RFID" selected → show Sealed & Unsealed bars  */}
      {scanType === "RFID" ? (
        <div
          className={`scanner-bottom-container ${
            type === "type2" ? "" : "blur-free-tier-content"
          }`}
        >
          <div className="scan-category">
            <div className="scan-info">
              Sealed Scans <span className="scan-number">{unbreakCount}</span>
            </div>
            <div className="scan-bar-container">
              <div
                className="scan-bar"
                style={{
                  width: `${perUnbreakWidth}%`,
                  backgroundColor: "#119927",
                  height: "12px",
                }}
              />
            </div>
          </div>

          <div className="scan-category">
            <div className="scan-info">
              Unsealed Scans <span className="scan-number">{breakCount}</span>
            </div>
            <div className="scan-bar-container">
              <div
                className="scan-bar"
                style={{
                  width: `${perBreakWidth}%`,
                  backgroundColor: "#EA6708",
                  height: "12px",
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        /**
         * If tenant = "morepen1", show a single progress bar that represents
         * ( totalScans / totalGenerated ) * 100%. 
         * Otherwise fallback to your default "Total Scans" bar.
         */
        <div className="scanner-bottom-container">
          {localStorage.getItem("tenant") === "morepen1" || localStorage.getItem("tenant") ==="pmbi-morepen1" ? (
            <div className="progress-bar-wrapper">
              {/* Show text for totals */}
              <div className="progress-bar-text">
                <div className="text-row">
                  <span className="text-label">Total Code Generated:</span>
                  <span className="text-value">{totalGenerated}</span>
                </div>
                <div className="text-row">
                  <span className="text-label">Total Scans:</span>
                  <span className="text-value">{notAttTagCount}</span>
                </div>
              </div>

              {/* Single progress bar container */}
              <div className="progress-bar-container">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${progressPercentage}%` }}
                >
                  {/* Optionally show the % or the fraction inside the bar */}
                  <span className="progress-bar-label">
                    {Math.floor(progressPercentage)}%
                  </span>
                </div>
              </div>
            </div>
          ) : (
            // Fallback for non-Morepen tenants
            <div className="scan-category">
              <div className="scan-info">
                Total Scans{" "}
                <span className="scan-number">{notAttTagCount}</span>
              </div>
              <div className="scan-bar-container">
                <div
                  className="scan-bar"
                  style={{
                    width: `${qrScansper}%`,
                    backgroundColor: "#119927",
                    height: "12px",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Scans;
