import React, { useState, useEffect, useRef } from "react";
import "./Order.css";
import ReloadIcon from "../assets/reload.png";
import filter from "../assets/filter.png";
import DownArrow from "../assets/dropdownblue.png";
import Status from "./Status/Status";
import axios from "axios";
import { format } from "date-fns";
import { QRCode } from "react-qrcode-logo";
import { fetchTenantData } from "../redux/counterSlice";
import { useDispatch } from "react-redux";

function getStatusStyle(status) {
  const baseStyle = {
    borderRadius: "8px",
    padding: "4px 15px",
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "18.75px",
    letterSpacing: "0.02em",
  };
  const styles = {
    pending: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Picked: {
      backgroundColor: "#FFF1E6",
      color: "#EA6708",
      ...baseStyle,
    },
    Transit: {
      backgroundColor: "#b3cee5",
      color: "#4682B4",
      ...baseStyle,
    },
    Delivered: {
      backgroundColor: "#DDFFE2",
      color: "#119927",
      ...baseStyle,
    },
    Downloaded: {
      backgroundColor: "#E6F0FF",
      color: "#0D6EFD",
      ...baseStyle,
    },
  };

  return <span style={styles[status]}>{status}</span>;
}

function Order() {
  const [expandedRow, setExpandedRow] = useState(null);
  const [isRotating, setIsRotating] = useState(false);
  const [open, setOpen] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const tenantDataRef = useRef([]);
  const [logoImage, setLogoImage] = useState("");
  const [logoOpacity, setLogoOpacity] = useState(1);
  const [qrStyle, setQRStyle] = useState("dots");
  const [logoSize, setLogoSize] = useState(40);

  const dispatch = useDispatch();

  const reloadData = () => {
    setIsRotating(true);
    setTimeout(() => {
      setIsRotating(false);
    }, 2000);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data && data.idToken && data.idToken.payload && data.idToken.payload.name) {
      dispatch(fetchTenantData(data.idToken.payload.name));
    }
  }, [dispatch]);

  const [url, setUrl] = useState();
  const handleClose = (a) => {
    setUrl(a);
    setOpen(!open);
  };

  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogoImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Get tenant from localStorage
  const tenants = localStorage.getItem("tenant")?.toLowerCase() || "";

  // ===================== FETCH ORDERS =====================
  useEffect(() => {
    const fetchData = async () => {
      if (tenants === "globalminingequipments1" || tenants === "gme1") {
        // 1) GME orders
        try {
          const response = await axios.get(
            "https://go83cmp8wk.execute-api.ap-south-1.amazonaws.com/dev/gme-orders"
          );
          const formattedData = response.data.orders.map((order) => ({
            orderDate: order.orderDate,
            orderno: order.orderID,
            productName: order.orderDetails,
            requestBody: {
              rows: [{ qty: order.qty, nfc: "N/A", price: "N/A" }],
            },
            status: order.status,
          }));
          // Sort orders by orderDate descending
          const sortedData = formattedData.sort(
            (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
          );
          setTenantData(sortedData);
          tenantDataRef.current = sortedData;
        } catch (error) {
          console.error("Error fetching GME orders:", error);
        }
      } else if (tenants === "morepen1" || tenants=="pmbi-morepen1") {
        // 2) Morepen orders
        try {
          const response = await axios.get(
            "https://w2d1g7akg3.execute-api.ap-south-1.amazonaws.com/dev/all"
          );
          // response.data.items array
          const items = response.data.items || [];
          // Sort by createdAt descending
          const sortedItems = items.sort((a, b) => b.createdAt - a.createdAt);
          setTenantData(sortedItems);
          tenantDataRef.current = sortedItems;
        } catch (error) {
          console.error("Error fetching Morepen orders:", error);
        }
      } else {
        // 3) Default fetch
        try {
          const response = await axios.get(
            `https://0xb8bptkil.execute-api.ap-south-1.amazonaws.com/prod/employee?employeeid=${tenants}`
          );
          const data = response.data.orderdetials.reverse();
          setTenantData(data);
          tenantDataRef.current = data;
        } catch (error) {
          console.error("Error fetching tenant orders:", error);
        }
      }
    };

    fetchData();
  }, [tenants]);

  // ===================== RENDER =====================
  return (
    <div className="orders">
      <div className="orders-header">
        <div className="orders-text">
          <p>Orders</p>
        </div>
      </div>

      {/* ===================== For Morepen or Others? ===================== */}
      {tenants === "morepen1" || tenants=="pmbi-morepen1" ? (
        /* --- TABLE for Morepen: Show (Date, Order ID, Product Name, Order Qty) --- */
        <div className="table-container">
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order Timestamp</th>
                <th>Order ID</th>
                <th>Product</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {tenantData &&
                Array.isArray(tenantData) &&
                tenantData.length > 0 ? (
                tenantData.map((item, index) => {
                  const created = new Date(item.createdAt);
                  const isValidDate = !isNaN(created);
                  return (
                    <tr key={index}>
                      <td>
                        {isValidDate ? (
                          <>
                            <div>{format(created, "MMMM dd yyyy")}</div>
                            <div className="order-table-timestamp">
                              {format(created, "HH:mm:ss")}
                            </div>
                          </>
                        ) : (
                          <div>Invalid Date</div>
                        )}
                      </td>
                      <td>{item.orderid}</td>
                      <td>{item.productName}</td>
                      <td>{item.productQty}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4">No orders found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        /* --- TABLE for all other tenants (existing logic) --- */
        <div className="table-container">
          <table className="orders-table">
            <thead>
              <tr>
                <th>Order Timestamp</th>
                <th>Order ID</th>
                <th>Product</th>
                {/* Only show 'Items' column if not GME */}
                {tenants !== "globalminingequipments1" && tenants !== "gme1" && (
                  <th>Items</th>
                )}
                <th>Status</th>
                {/* Only show 'Amount' column if not GME */}
                {tenants !== "globalminingequipments1" && tenants !== "gme1" && (
                  <th>Amount</th>
                )}
                <th>Quantity</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tenantData &&
              Array.isArray(tenantData) &&
              tenantData.length > 0 ? (
                tenantData.map((order, index) => {
                  // Some tenants have "orderDate", some have "createdAt". Adjust as needed:
                  const rawDate =
                    order.orderDate ||
                    order.createdAt ||
                    Date.now(); // fallback if missing
                  const orderDate = new Date(rawDate);
                  const isValidDate = !isNaN(orderDate);

                  // For default structure: order.orderno, order.productName, etc.
                  // GME fetch maps to { orderDate, orderno, productName, requestBody, status }
                  // Others have { orderno, productName, requestBody, status }, etc.

                  return (
                    <tr key={index}>
                      <td>
                        {isValidDate ? (
                          <>
                            <div>{format(orderDate, "MMMM dd yyyy")}</div>
                            <div className="order-table-timestamp">
                              {format(orderDate, "HH:mm:ss")}
                            </div>
                          </>
                        ) : (
                          <div>Invalid Date</div>
                        )}
                      </td>
                      <td>{order.orderno || order.orderid}</td>
                      <td>{order.productName}</td>

                      {/* Items column if not GME */}
                      {tenants !== "globalminingequipments1" &&
                        tenants !== "gme1" && (
                          <td>
                            {order.requestBody?.rows &&
                              Array.isArray(order.requestBody.rows) &&
                              order.requestBody.rows.map((item, i2) => (
                                <div className="order-table-items" key={i2}>
                                  {item.nfc}
                                </div>
                              ))}
                          </td>
                        )}

                      {/* Status */}
                      <td>{getStatusStyle(order.status || "pending")}</td>

                      {/* Amount column if not GME */}
                      {tenants !== "globalminingequipments1" &&
                        tenants !== "gme1" && (
                          <td>
                            {order.requestBody?.rows &&
                              Array.isArray(order.requestBody.rows) &&
                              order.requestBody.rows.reduce((acc, item) => {
                                const price = parseFloat(item.price) || 0;
                                const qty = item.qty || 0;
                                return acc + price * qty;
                              }, 0)}
                          </td>
                        )}

                      {/* Quantity */}
                      <td>
                        {order.requestBody?.rows &&
                          Array.isArray(order.requestBody.rows) &&
                          order.requestBody.rows.reduce((acc, item) => {
                            return acc + (item.qty || 0);
                          }, 0)}
                      </td>

                      {/* Expand arrow */}
                      <td onClick={() => toggleRow(index)}>
                        {tenants !== "globalminingequipments1" &&
                          tenants !== "gme1" && (
                            <img
                              src={DownArrow}
                              alt="Expand"
                              className={`order-expand-icon ${
                                expandedRow === index ? "rotated" : ""
                              }`}
                            />
                          )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7">No orders found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Order;
